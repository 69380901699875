<template>
  <div class="product-wrap">
    <div class="productTop">
      <div class="item1">
        <div class="item1Img">
          <!-- ../../../assets/shoppingCart/product.png -->
          <img :src="item.materialGroupId+'/'+item.productId+'/180-180/'+item.picture" alt />
          <h-img 
          :imgUrl="item.materialGroupId+'/'+item.productId+'/180-180/'+item.picture"
        
          ></h-img>
        </div>
        <div class="item1Val">
          <div class="item1Title">
            <p v-if="item.productName">{{item.productName}}</p>
          </div>
          <p>
            <span style="margin-right:5px;">品牌：海信</span>
            <span v-if="item.color">颜色：{{item.color}}</span>
          </p>
          <p v-if="item.loadVolume">体积：{{item.loadVolume}}m³</p>
        </div>
      </div>
      <div class="item2">
        <div class="item2Title">
          ￥{{item.price}}
          <span style="margin-left:5px;" v-if="item.basePrice">￥{{item.basePrice}}</span>
        </div>
        <div class="item2Item">
          <p>固定折扣：{{item.fixedDiscount}}%</p>
          <p>定价组：{{item.priceGroupName}}</p>
<!--          <p>补差类型：{{item.makeUpTypeName}}</p>-->
          <!-- <p>单台返利30元</p> -->
        </div>
      </div>
      <div class="item3">
        <div class="item3Title">×{{item.quantity}}</div>
        <div class="item3Item">
          <div class="item3Items">
            <p v-if="item.customerCode">海信库存:{{item.customerCode}}</p>
            <p v-if="item.shareOrgFlag">共享库存:{{item.shareOrgFlag}}</p>
          </div>
          <!-- <div class="item3Items">
            <p>待总部评审(20)</p>
            <p>待发货(10)</p>
            <p>已商收(24)</p>
            <p>待发货(20)</p>
            <p>甩货(10)</p>
          </div>
          <p>已取消(34)</p>-->
        </div>
      </div>
      <div class="item4">
        <div class="item4Title">小计：￥{{item.quantity*item.price}}</div>
        <!-- 采购订单详情取消 -->
        <!-- <p class="purchase" @click="cancleClick(item)">取消</p> -->
      </div>
    </div>
    <!-- 通用结算页面显示内容 -->
    <!-- v-if="settlement" -->
    <div>
      <div class="line"></div>
      <div class="settlement">
        <div class="settlementChose" @click="handleChange(makeShure)">
          <a-radio :checked="makeShure"></a-radio>
        </div>
        <div>
          <div class="settleTitle">{{item.priceGroupName}}</div>
          <div class="settlementVal">
            <p>批次：C</p>
            <p>提货价：￥{{item.standardPrice}}</p>
            <p v-if="item.reduce">直扣：￥{{item.reduce}}</p>
            <p v-if="item.specialPrice">开票价：￥{{item.specialPrice}}</p>
<!--            <p>总数量：10</p>-->
<!--            <p>可购数量：7</p>-->
<!--            <p>已购数量：3</p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    item: {
      required: true
    },
    settlementItem: {
      required: false
    }
    // // 特购专区
    // settlement: {
    //   required: true,
    //   default: false
    // },
  },
  data() {
    return {
      makeShure: true
    };
  },
  methods: {
    // changeOpened(item) {
    //   this.$set(item, "opened", !item.opened);
    //   // item.opened = !item.opened
    // },
    // // 点击取消按钮
    cancleClick(data) {
      this.$emit("cancleClick", data);
    },
    // 特购页面点击是否需要特购优惠
    handleChange(data) {
      this.makeShure = !this.makeShure;
      this.$emit("settleChose", data);
    },
    // 点击购物车
    carClick() {
      this.$emit("carClick", "");
    }
  }
};
</script>

<style scoped lang="less">
.product-wrap {
  width: 1140px;
  // height: 250px;
  padding: 23px 0 20px 24px;
  // margin: 20px auto 0;
  // margin-top: 24px;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  border: 1px solid #eeeeee;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 392px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      .item1Img {
        margin-right: 16px;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .item1Val {
        text-align: left;
        padding-top: 5px;
        box-sizing: border-box;
        .item1Title {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          p {
            // width: 240px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #262626;
            line-height: 20px;
            margin-top: 0;
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {
          margin-top: 8px;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #aaaaaa;
          line-height: 16px;
        }
      }
    }
    .item2 {
      width: 210px;
      margin-left: 60px;
      .item2Title {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        padding-top: 5px;
        box-sizing: border-box;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #aaaaaa;
        }
      }
      .item2Item {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #aaaaaa;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        p {
          margin-right: 10px;
          margin-top: 8px;
        }
      }
    }
    .item3 {
      width: 210px;
      margin-left: 24px;
      .item3Title {
        padding-top: 5px;
        box-sizing: border-box;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .item3Item {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #aaaaaa;
        line-height: 16px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 164px;
      padding-top: 5px;
      box-sizing: border-box;
      margin-left: 24px;
      .item4Title {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .purchase {
        font-family: PingFangSC-Medium;
        font-size: 12px;
        color: #777777;
        line-height: 16px;
        cursor: pointer;
        margin-top: 8px;
      }
      .purchase:hover {
        color: #00aaa6;
      }
    }
  }

  .line {
    width: 1092px;
    height: 1px;
    border: 1px solid #eeeeee;
    margin-top: 22px;
  }
  .settlement {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .settleTitle {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #777777;
      line-height: 16px;
      margin-left: 24px;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    .settlementVal {
      width: 800px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: 24px;
      p {
        width: 120px;
        text-align: left;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #aaaaaa;
        line-height: 16px;
        margin-right: 50px;
        margin-top: 8px;
      }
    }
  }
}
</style>