import Step from '@/components/global/Step/Step.vue'
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
// import {from} from 'core-js/fn/array';
import { checkShipTo, getAdressapi, SalesShopInfoList, serviceInformation } from "./../../views/settlement/api";
import ProductItem from './components/ProductItem.vue'
import { checkIsOneProject } from "@/views/specialZoneGC/api";
import {
  // orderWeek,
  // 购物车查询
  // orderCar,
  // 统仓联系人
  // orderAddress,
  // 分销商 分销地址
  // showAnother,
  // 收货地址切换带出联系人
  //。。。。
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  // 要求到货周
  // getWeek,
  // 商品列表
  //goodsList,
  // 获取商品列表库存数
  getNumber,
  // 购物车提交
  // cartSubmit,
  // 特惠订单提交
  specialSubmit,
  // 查看预占用额度明细
  quotaDeatail,
  // 特惠专区接口
  specialZone,
  // 充值
  saveMoney,
  // 充值记录
  saveHisetory
} from './api';
import {
  saveMoneyType
} from "@/common/constant";
import coverBuy from './components/coverBuy';
import moment from 'moment'
import {findList, findListLabel, saveInfo, saveInfoLabel} from "@/views/userOperations/IntendedUserEntry/api";
import {getRetailPrice} from "@/views/stockCenter/adjustmentApply/components/api";
import { ctsApi, fxZsyhGetDeliveryMethod } from "@/views/channel/api";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { checkRetailL } from "@/views/channelInput/saleNew/api";
import { getUserList } from "@/views/userOperations/IntentionList/api";
import addressModal from "@/views/settlement/components/addressModal";
export default {
  name: "Home",
  components: {
    Step,
    ProductItem,
    coverBuy,
    addUserSource,
    addUserTips,
    addressModal
  },
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/package",
          name: "package",
          title: "套购专区"
        },
        {
          title: "套购订单详情"
        }
      ],
      modelShow: false,
      // 查看占用明细列表
      columns: [{
          title: '销售组织',
          dataIndex: 'orgName',
          key: 'orgName',
          width:100
        },
        {
          title: '物料组',
          dataIndex: 'matklName',
          key: 'amatklNamege',
          width:100
        },
        {
          title: '订单编码',
          dataIndex: 'orderCode',
          key: 'orderCode',
          width:200
        },
        {
          title: '订单类型',
          dataIndex: 'orderType',
          key: 'orderType',
          width:100
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          width:100
        },
        {
          title: '商品型号&数量',
          dataIndex: 'productAndQtys',
          key: 'productAndQtys',
          width:250,
          ellipsis: true,
        },
        // {
        //   title: '商品数量',
        //   dataIndex: 'qty',
        //   key: 'qty',
        //   width:100
        // },
        {
          title: '付款状态',
          dataIndex: 'fkStatus',
          key: 'fkStatus',
          width:100
        }, {
          title: '订单金额',
          dataIndex: 'orderAmt',
          align: 'right',
          key: 'orderAmt',
          width:100
        }, {
          title: '预占用金额',
          dataIndex: 'waitBalance',
          key: 'waitBalance',
          align: 'right',
          width:120,
          scopedSlots: { customRender: "type" },
        }, {
          title: '要求到货周次',
          dataIndex: 'weekName',
          key: 'weekName',
          width: 200
        },
        {
          title: '下单日期',
          dataIndex: 'createdDate',
          key: 'createdDate',
          width:200
        },
      ],
      // 查看占用明细列表
      data: [],
      bankChecked: true,
      visible: false,
      steps: ['选择商品', '确认订单信息', '提交订单'],
      currentStep: 2,
      options: [],
      optionsT: [],
      prods: [],
      //form
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      other: '',
      form: {
        name: '',
        billName: undefined,
        address: undefined,
        agency: undefined,
        askWeekend: undefined,
        distributor: undefined,
        distributorAddress: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',

      },
      arrivalTimeStr: '',
      rules: {
        name: [{
            required: true,
            message: '请输入联系人',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 5,
            message: 'Length should be 3 to 5',
            trigger: 'blur'
          }
        ],
        phone: [{
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          },
          {
            min: 7,
            max: 11,
            message: '请输入正确的联系方式',
            trigger: 'blur'
          },
        ],
        billName: [{
          required: true,
          message: '请选择开票户头',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请选择收货地址',
          trigger: 'change'
        }],
        agency: [{
          required: true,
          message: '请选择海信办事处',
          trigger: 'change'
        }],
        askWeekend: [{
          required: true,
          message: '请选择周',
          trigger: 'change'
        }],
        distributor: [{
          required: true,
          message: '请选择分销商',
          trigger: 'change'
        }],
        distributorAddress: [{
          required: true,
          message: '请选择分销商地址',
          trigger: 'change'
        }],
        date1: [{
          required: true,
          message: 'Please pick a date',
          trigger: 'change'
        }],
        type: [{
          type: 'array',
          required: true,
          message: 'Please select at least one activity type',
          trigger: 'change',
        }, ],
        resource: [{
          required: true,
          message: 'Please select activity resource',
          trigger: 'change'
        }, ],
      },
      // 联系人
      contacts: '',
      // 联系人手机号
      phoneCon: '',
      // 开票户头账号
      choseAccounts: [],
      // 是否显示其他开票户头
      accountShow: false,
      // 开票户头
      invoice: '',
      // 开票户头id
      invoiceId: '',
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      endTime: '',
      // 控制分销商及分销商地址是否显示
      distributors: false,
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: ' ',
      distrAdd: false,
      distrName: '',
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: '',
      // 供应商为海信时显示供应商为物料组
      suppilers: '',
      orgCode: "", // 供应商code  2601这种
      // 配送方式选择直配到用户
      userAdderss: false,
      // 用户详细地址
      userDetailAddress: '',
      // 用户省市区级联
      userCity: [{
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [{
            value: 'hangzhou',
            label: 'Hangzhou',
            children: [{
              value: 'xihu',
              label: 'West Lake',
            }, ],
          }, ],
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          children: [{
            value: 'nanjing',
            label: 'Nanjing',
            children: [{
              value: 'zhonghuamen',
              label: 'Zhong Hua Men',
            }, ],
          }, ],
        },
      ],
      // 省市区三级联动
      provinceData: ['Zhejiang', 'Jiangsu'],
      cityData: {
        Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],
        Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],
      },
      cities: ['Hangzhou', 'Ningbo', 'Wenzhou'],
      secondCity: '',
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: '',
      // 收货地址id
      receiveID: '',
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      houseAddress: '仓库地址',
      // 统仓联系人禁止修改
      TCInt: false,
      // 配送方式ID
      sendId: '',
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: '',
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      // proObj: {},
      // 开票户头
      billToDTOs: [],
      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: '',
        DISADD: ''
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: '',
        orgId: '',
        matklId: ''
      },

      // 要求到货周
      arriveOptions: [{
          label: '要求到货周 N(20200809-20200910)',
          value: 0
        },
        {
          label: '要求到货周 N(20200809-20200910)',
          value: 1
        },
      ],
      selected: '',
      GoodsList: [],
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},


      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: '',
      // 上个页面传过来的参数
      RouterParams: {
        flag: 1,
        counts: '1,2,1',
        ids: '2,3,2',
        mainId: 2
      },
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: '',
      orderCartIds: {},
      // 配送方式
      trans: '',
      hisenseOff: false,
      officehis: [],
      officeCode: '',
      receivehis: '',
      officeHis: false,
      matklId: '',
      orgId: '',
      orgtitle: '',
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      startTime: '',
      maxEndDate: '',
      // 是否使用返利
      tradeType: '',
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: true,
      // 预占用明细分页组件
      pager: {
        count: '',
        pageNo: 1,
        pageSize: 10
      },
      saveMoneyValue: {},
      URLTO: '',
      pageLoadTable: false,
      saleShopList:[], // 销量所属门店
      receiveShop:'', // 销量所属门店名称
      salesShopInfoId:'',  // 销量所属门店id
      fwlbId:[],
      // 服务列表数据
      FWlist:[],
      provinceCode: '',//省
      cityCode: '',//市
      districtCode: '',//区
      townCode: '',//县乡
      defaultTown:'',
      companyList:[],
      fetching:false,
      addressHx:[],
      visibleaA: false,// 新建用户来源弹窗
      visibleTips: false,// 新建用户标签弹窗
      userSorceList: [],
      SorceIds: '',// 选中的用户来源
      userTipsList: [],
      TipsIds: [],// 选中的标签
      TipsNames: [],// 选中的标签
      SelectItem:[],
      isAddressSupport:'',
      sexId:1,
      sexoptions: [{id: 1, name: '男士'}, {id: 2, name: '女士'}], // 用户性别列表
      serviceCharge:0,
      orderServiceFeeItemDtoList:[],
      warehouseInfoId:'',
      isShowLogisticsIcon:false,
      phone:'',
      text1:'',
      text2:'',
      haveDeliveryFee:false,
      transDefault:'',// 配送方式回显 index
      showSorce:false,
      sourceName:'',
      endOpen:false,
      startShowTime:'',
      addressDftVal:[], // 收货地址回填的值
      visibleAddress:false,
      newTown:'',
      isC20Business: false, // 是否C20商家
    };
  },

  mounted() {
    this.RouterParams = this.$route.query;
    let SCounts = this.$route.query.counts.split(',');
    let SIds = this.$route.query.ids.split(',')
    for (var i = 0; i < SIds.length; i++) {
      let obj = {
        id: SIds[i],
        count: SCounts[i]
      }
      this.submitGoods.push(obj);
    }
    // 购物车查询
    this.carList()
    this.URLTO = saveMoneyType
    this.getuserSorceList()
    this.getuserTipList()
    const data1 = this.$getAlertInfoByCode('TS0248')
    const data2 = this.$getAlertInfoByCode('TS0249')
    this.text1 = data1.msgDesc.split('${phone}')
    this.text2 = data2.msgDesc
    this.phone = data1.remark
  },
  watch: {
    GoodsList:{
      immediate: true,
      handler(val) {

        val.forEach((item, index)=>{
          item.idEdit = false
          // 获取政策台返信息
          const { pricingGroupCode, baseProductInfo } = item;
          try {
            ctsApi({
              serviceCode: "agentDirectDealerReturnPolicy",
              bigContractNo: "",
              companyCode: this.orgCode,
              contractNo: "",
              distributorsCisCode: "",
              dlMdmCode: this.$store.state.user.userInfo.customer.customerCode,
              model: baseProductInfo.modelSap || baseProductInfo.zzprdmodel,
              pricingGroup: pricingGroupCode,
              tableRow: "",
              isActivityArea: false
            }).then(res=>{
              if(res && res.data && res.data.data && res.data.data.model){
                item.isShowRebate = true;
                item.returnAmount = res.data.data.returnAmount;
                item.upperLimit = res.data.data.upperLimit;
                this.$set(this.GoodsList[index], index, item)
              }
            })
          } catch (error) {
          }
          let retailData = {
            cisCode:this.$store.state.user.userInfo.customer.cisCode,
            productId: item.baseProductInfo.id,
            refreshPrice: true,
            type: 3,
          }
          getRetailPrice(retailData).then(res=>{
            item.salePrice = this.$util.getFloat(res.data.price,2)
            item.retailPrice = this.$util.getFloat(res.data.price,2)
            this.$forceUpdate()
          })
          checkRetailL({id:item.baseProductInfo.id}).then(res => {
            if(res.data && res.data.code == 0){
              const {type, money, bei, min, max, haveRetailL } = res.data;// 0不限制  1限制
              item.min = min;
              item.bei = bei;
              item.type = type;
              item.max = max;
              item.salePrice = this.$util.getFloat(Number(money),2)
              item.retailPrice = this.$util.getFloat(Number(money),2)
              item.haveRetailL = haveRetailL;
            }
          })

        })

        }
    },
    townCode:{
      immediate: true,
      handler(val) {
        if((this.trans == '502004' || this.trans == '502008')&&this.townCode ){
          this.getFWF()
        }
      }
    },
    warehouseInfoId:{
      immediate: true,
      handler(val) {
        if((this.trans == '502004' || this.trans == '502008')&&this.townCode ){
          this.getFWF()
        }
      }
    },
    trans:{
      immediate: true,
      handler(val) {
        if((this.trans == '502004' || this.trans == '502008')&&this.townCode ){
          this.getFWF()
        }
      }
    },
  },
  methods: {
    changePhoneNum() {
      this.phoneCon =  this.phoneCon.replace(/\D/g, '')
      if( this.phoneCon.trim().length == 11&&this.salesShopInfoId&&(this.trans=='502004'||this.trans=='502008')){
        let data = {
          phone: this.phoneCon,
          shopInfoId:this.salesShopInfoId
        }
        getUserList(data).then(res=>{
          if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            if(!this.contacts){
              this.contacts =  res.data.list[0].userName
            }
            this.SorceIds =  res.data.list[0].sourceId? res.data.list[0].sourceId:5007684
            this.sourceName =  res.data.list[0].sourceName?res.data.list[0].sourceName:'自然到访'
            this.showSorce = (this.SorceIds ==  res.data.list[0].sourceId)?true:false

            this.sexId = res.data.list[0].genderName=='男' ? 1 : (res.data.list[0].genderName=='女'? 2 : 1 )
            if(this.userTipsList.length>0){
              this.TipsNames = []
              this.TipsIds = []
              this.userTipsList.forEach(item=>{
                item.checked = false

              })
            }
            if( res.data.list[0].userLableList.length>0 && this.userTipsList.length > 0){
              this.userTipsList.forEach(item=>{
                res.data.list[0].userLableList.forEach(twoItem=>{
                  if(item.id == twoItem.labelId) {
                    item.checked = true
                    this.TipsIds.push(twoItem.labelId)
                    this.TipsNames.push(twoItem.labelName);
                  }
                })
              })
            }else {
              this.sexId = 1
              this.TipsIds = []
              this.TipsNames = []
              this.$nextTick(()=>{
                if(this.userTipsList.length>0){
                  this.userTipsList.forEach(item=>{
                    item.checked = false
                    if(item.id  == 5007817){
                      item.checked = true
                      this.TipsIds.push(item.id)
                      this.TipsNames.push(item.label);
                    }
                  })
                }
              })
              this.SorceIds = 5007684
              this.sourceName =  '自然到访'
              this.showSorce = false
            }

          } else {
            this.sexId = 1
            this.addressHx = []
            this.showSorce = false
            this.TipsIds = []
            this.TipsNames = []
            this.$nextTick(()=>{
              if(this.userTipsList.length>0){
                this.userTipsList.forEach(item=>{
                  item.checked = false
                  if(item.id  == 5007817){
                    item.checked = true
                    this.TipsIds.push(item.id)
                    this.TipsNames.push(item.label);
                  }
                })
              }
            })
            this.SorceIds = 5007684
            this.sourceName =  '自然到访'
            this.showSorce = false
          }
        })
      }

    },

    // 查询服务费
    getFWF(){
      if( !this.townCode ){
        this.$message.warning('请选择省市区县')
        return
      }
      // if( !this.warehouseInfoId ){
      //   this.$message.warning('请选择统仓地址')
      //   return
      // }
      this.arrivalTimeStr = ''
      this.startShowTime = ''
      let orderServiceFeeItemDtoList = []
      this.GoodsList.forEach(item=>{
        orderServiceFeeItemDtoList.push({
          "productId": item.baseProductInfo.id,		//产品id
          "warehouseInfoId":  '',			//仓库werks-lgort，例如2600-1532，dms代理商下单必传，其他情况传空
          "qty": item.quantity,
          zzprdmodel:item.baseProductInfo.zzprdmodel,
          orgId:this.orgId
        })
      })
      let data = {
        "townCode":  this.townCode,
        "countyCode": this.districtCode,
        "cityCode":  this.cityCode,
        "provinceCode":  this.provinceCode ,
        "warehouseInfoId":   this.warehouseInfoId?this.warehouseInfoId:'',			//发货仓库id，信天翁代理商下单必填，信天翁分销商下单或者dms下单必须传空
        "shopId":  '',			//门店idthis.salesShopInfoId
        "documentSource":"1",			//dms下单传5，其他随便传个不是5的数就行
        "orderServiceFeeItemDtoList": orderServiceFeeItemDtoList,
        trans:this.trans
      }
      this.pageLoadFlag = true
      findMoneyBuyBegin(data).then(res => {
        // 获取送达时间
        if(res.data && res.data.data && res.data.data.timelinessDate){
          this.arrivalTimeStr =  res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
          this.startShowTime = res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
        } else{
          //  如果接口没返回数据 就默认当前日期加3天
          this.arrivalTimeStr =  this.$util.getDateSpecify(3)
          this.startShowTime = this.$util.getDateSpecify(3)
        }
        if(res.data.code == 0){
          this.serviceCharge = res.data.data.totalAmount
          this.orderServiceFeeItemDtoList = res.data.data.orderServiceFeeItemDtoList
          this.orderServiceFeeItemDtoList.forEach((itemFirst,indexFirst)=>{
            this.GoodsList.forEach((item,index)=>{
              if( itemFirst.productId == item.baseProductInfo.id ) {
                this.$nextTick(()=> {
                  this.$set(item,'serviceMoney',itemFirst.serviceFee)
                })
              }
            })
          })
          this.pageLoadFlag=false
          return
        }
        if(res.data.code == 1 ){
          this.pageLoadFlag=false
          this.serviceCharge = 0
          this.GoodsList.forEach((item,index)=>{
              this.$nextTick(()=> {
                this.$set(item,'serviceMoney','0.00')
              })
          })
        }
        // else  {
        //   this.serviceCharge = 0
        //   this.GoodsList.forEach((item,index)=>{
        //     item.serviceMoney = 0.00
        //   })
        // }
      }).catch(error=>{
        //  如果接口没返回数据 就默认当前日期加3天
        this.arrivalTimeStr =  this.$util.getDateSpecify(3)
        this.startShowTime = this.$util.getDateSpecify(3)
        this.pageLoadFlag=false

      })
    },
    changeSEX(e) {
      this.sexId = e.target.value;
    },
    changePrice(item){
      this.$forceUpdate()
    },
    openSource(){
      this.visibleaA = true
    },
    openTips(){
      this.visibleTips = true
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList = res.data.data;
          if(this.userSorceList.length > 0) {
            this.userSorceList.forEach(item => {
              item.id = (item.id).toString();
              item.ischerk = false;
            });
          }

          this.pageLoadFlag = false;
        }
        this.pageLoadFlag = false;

      });
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList = res.data.data;
          if (this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false;

        }
        this.pageLoadFlag = false;
      });
    },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id;
      this.$forceUpdate();
    },
    changeBtnnew(item){
      this.SorceIds = item;
    },

    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked;
      this.$forceUpdate();
      this.TipsIds = [];
      this.TipsNames = [];
      this.userTipsList.forEach(items => {
        if (items.checked == true) {
          this.TipsIds.push(items.id);
          this.TipsNames.push(items.label);
        }
      });
      this.$forceUpdate();
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item,name) {
      this.TipsIds = [];
      this.TipsNames= [];
      this.TipsIds = item;
      this.TipsNames = name
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value,value2) {
      if (!value) {
        this.SorceIds = value2
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
              {
                id: res.data.data,
                source: value
              }
          );
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    getReasonTip(value, value1) {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
              {
                id: res.data.data,
                label: value,
                checked: true
              }
          );
          this.TipsIds.push(res.data.data);
          this.pageLoadFlag = false;

        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    disabledDateS(current) {
      // let nowDate = moment().format("YYYY-MM-DD")
      // 目前这个值是写死的 后期需要对接口
      let three = moment(this.startShowTime)
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(this.startShowTime) || current > three.add(29, 'd')
    },
    arrivalTimeFocus(){
      if(!this.arrivalTimeStr && this.haveDeliveryFee) {
        this.$message.warning('请先填选相关信息')
        return
      } else {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(){
      if(this.arrivalTimeStr){
        this.endOpen = true;
        return
      }
    },
    // 期望送货日期时间选择
    arrivalTimeChange(value,dateString) {
      this.arrivalTimeStr = dateString;
      this.endOpen = false
    },
    onAddressChange(addressValArr) {
      // 老地址
      let oldAdress = this.districtCode
      // 长度如果等于2 乡镇就置空
      if(addressValArr&&addressValArr.length == 2) {
        this.districtCode = '';
      }
      // 长度如果等于3 区县就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.townCode = '';
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.provinceCode = item;
          }
          if (index == 1) {
            this.cityCode = item;
          }
          if (index == 2) {
            this.districtCode = item;
          }
          if (index == 3) {
            this.townCode = item;
          }
        });
        this.addressHx=[this.provinceCode,this.cityCode,this.districtCode,this.townCode]

      } else {
        this.provinceCode = ''
        this.cityCode = ''
        this.districtCode = ''
        this.townCode = ''
        this.addressHx = []
        this.userDetailAddress = ''
        this.arrivalTimeStr = ''
        this.startShowTime =''
      }
      // 如果之前的三级地址修改了  就清空详细地址
      if(oldAdress !=  this.districtCode ){
        this.userDetailAddress = ''
        this.addressHx = []
        this.userDetailAddress = ''
      }
      this.companyList = []
    },
    blurAddress(){
      if(!this.districtCode) {
        this.userDetailAddress = ''
        return
      }
    },
    // 获取下拉地址
    getAdress(value) {
      if(!this.districtCode) {
        this.$message.warning('请先选择省市区县')
        this.$refs.address.blur()
        this.userDetailAddress = ''
        return
      }
      this.userDetailAddress = value;
      if(!this.userDetailAddress ){
        this.companyList = []
        return
      }
      if(this.userDetailAddress  && this.userDetailAddress.length < 4 && this.userDetailAddress.length > 0){
        this.companyList = []
        return
      }
      let data  = {
        searchstr: this.userDetailAddress,
        region:this.districtCode
      }
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data!==null && res.data.data.pois && res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList =[]
        }

        this.fetching = false;
      });

    },
    //查询数据
    search(vaule,e) {
    },
    async sumbitaaa() {
      this.isLoading = true
      this.isLoading = false
      this.orderSubmit()
      return
    },
    // 服务列表查询
    getService() {
      let data  = {}
      serviceInformation(data).then(res=>{
        this.FWlist = res.data
      }) 
    },
    // 销量所属门店查询
    getsaleShopList() {
      let data = {
        orgId: this.orgId,
        matklId: this.matklId
      }
      SalesShopInfoList(data).then(res=>{
        this.saleShopList = res.data.list
        this.receiveShop = res.data.list[0].name;
        this.salesShopInfoId = res.data.list[0].code;
      })
    },
    // 更改销量所属于门店
    handleChangeshopList(value, e) {
      this.receiveShop = value;
      this.salesShopInfoId = e.key;
      this.arrivalTimeStr = ''
      this.startShowTime = ''
      this.changePhoneNum()
    },

    // 在线充值
    saveMoneyOnline() {
      saveMoney().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPay = document.getElementById("selfOrderPay");
          selfOrderPay.submit();
        })
      })
    },
    // 充值记录
    saveMoneyOnlineHis() {
      saveHisetory().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPayh = document.getElementById("selfOrderPayh");
          selfOrderPayh.submit();
        })
      })
    },
    //是否返利点击
    tradeOnChange(e) {
      // e.target.checked = !e.target.checked;
      this.isTradeChecked = e.target.checked;

    },
    // 购物车查询
    carList() {
      let data = {
        mainId: this.RouterParams.mainId,
        flag: this.RouterParams.flag
      }
      specialZone(data).then(res => {
        this.pageLoadFlag = false;
        // 组织编码 物料组编码
        this.matklId = res.data.matklId;
        this.orgId = res.data.orgId;
        let data = {
          orgId: this.orgId,
          id: this.$store.state.user.userInfo.customer.id, // 商家id
        }
        checkIsOneProject(data).then(res=>{
          this.isC20Business = res.data.data
        })
        // 判断是否是统仓
        this.shareFlag = res.data.shareFlag;
        // 分销商地址
        this.choseDistribution = res.data.marketAddress
        this.startTime = res.data.maxDate;
        if (res.data.shareFlag == 'Y') {
          this.houseAddress = '统仓地址'
          // this.distributors = true
          this.officeHis = true;
          this.dissize = true
          // 统仓收货地址   下面有
          // this.orderDis = res.data.customerAddressDTOs;
          // this.receive = res.data.customerAddressDTOs[0].address;
          // this.receiveID = res.data.customerAddressDTOs[0].id;
          // 海信办事处地址
          if(res.data.office.length>0) {
            this.officehis = res.data.office;
            this.officeCode = res.data.office[0].code;
            this.receivehis = res.data.office[0].name;
          }
        
          // getconnect(res.data.office[0].code);
          // 为统仓收货时 联系人不可修改
          this.TCInt = true;
          // orderAddress({
          //   sendToId: res.data.customerAddressDTOs[0].id
          // }).then(res => {

          //   this.contacts = res.data.contact;
          //   this.phoneCon = res.data.phone;
          // }).catch(err => {

          // })
        } else if (res.data.custMarketModels) {
          if (res.data.custMarketModels.length > 0) {
            // 分销商地址
            this.choseDistribution = res.data.custMarketModels
            // 当用户选择直配到分销商时
            // 获取分销商列表
            this.DisList = res.data.custMarketModels;
            // 分销商id
            this.distributionAdd.DISCODE = res.data.custMarketModels[0].customerInfoId;
            // 分销商名称 
            this.distrName = res.data.custMarketModels[0].customerInfoName

            // 请求分销商 地址参数 orgId
            this.DISADDPARAMS.orgId = res.data.orgId; // 接口没有这个参数
            this.DISADDPARAMS.matklId = res.data.matklId;
            this.DISADDPARAMS.customerId = res.data.custMarketModels[0].customerInfoId;
            // this.getDISADDList();
          }
          // 预付比例
          this.advancePayRate = res.data.advancePayRate;

        }
        //   // 是否可以使用返利
        this.tradeType = res.data.tradeType == '01' ? true : false;
        this.isTradeChecked = res.data.tradeType == '01' ? true : false;
        //  要求到货周
        // getWeek({
        //   orgId: res.data.orgId,
        //   matklId: res.data.matklId
        // }).then(res => {

        //   this.arriveOptions = res.data.list;
        //   this.selected = res.data.list[0].name;
        //   this.endTime = res.data.list[0].code;
        // })
        //   // 点击提交按钮提交参数
        this.orgAndGroup = res.data.matklId
        this.maxEndDate = res.data.maxDate
        //   // end 点击提交按钮参数

        this.obj = res.data;
        // 配送方式
        // if( res.data.deliveryList.length>0) {
        //   this.options = res.data.deliveryList;
        //   // 默认配送方式id
        //   this.trans = res.data.deliveryList[0].id
        // }
        
        if (res.data.billToDTOs.length > 0) {
          // 开票户头
          this.billToDTOs = res.data.billToDTOs;
          // 开票户头ID 及 公司名称
          this.invoice = this.billToDTOs[0].drawerName;
          this.invoiceId = this.billToDTOs[0].id;
        }

        // 省 list
        // this.provices = res.data.provices;
        // // this.proObj.proviceCode = res.data.defaultProvice;
        // this.provinceCode = res.data.defaultProvice;
        // this.cityCode = res.data.defaultCity;
        // this.districtCode = res.data.defaultDistrict;
        // this.townCode  = res.data.defaultTown;
        // this.addressHx = [this.provinceCode,this.cityCode,this.districtCode,this.townCode]

        // 收货地址
        if (res.data.customerAddressDTOs.length > 0) {
          this.orderDis = res.data.customerAddressDTOs;
          this.receive = res.data.customerAddressDTOs[0].address;
          this.receiveID = res.data.customerAddressDTOs[0].id;
          this.warehouseInfoId =  res.data.customerAddressDTOs[0].shareWarehouseId;

          //  初始化获取手机号
          getPhone({
            sendToId: res.data.customerAddressDTOs[0].id
          }).then(res => {
            this.contacts = res.data.contact == '无' ? '' : res.data.contact;
            this.phoneCon = res.data.phone == '无' ? '' : res.data.phone;
          })
        }


        // 商品行数据
        for (var i = 0; i < res.data.detailList.length; i++) {
          for (var j = 0; j < this.submitGoods.length; j++)
            if (this.submitGoods[j].id == res.data.detailList[i].id) {
              res.data.detailList[i].quantity = this.submitGoods[j].count;
            }
        }
        this.GoodsList = res.data.detailList;
        //商品件数 体积合计 合计金额
        let num = 0,
          volume = 0,
          amount = 0;
        //返利金额 列表计算返利比例后的返利金额 与 this.TotalPayment.rebateAccount返利金额相比 取较小值
        let rebate = 0;
        for (const item of this.GoodsList) {
          num += Number(item.quantity);
          volume += item.quantity * item.loadVolume;
          amount += item.quantity * item.standardPrice;
          //返利金额
          rebate += item.quantity * item.billPrice * (item.rebate / 100);

        };
        this.goodsNum = num;
        this.goodsVolume = this.$util.getFloat(volume, 3);
        this.goodsPrice = this.$util.getFloat(amount, 2);
        // 获取配送方式
        this.$nextTick(()=>{
          const itema = [{
            orgId: this.orgId,
            matklCode: this.GoodsList[0].baseProductInfo.matklCode,
            productIds: this.GoodsList.map(el => {
              return {
                id: el.baseProductInfo.id
              }
            })
          }]
          let data = {
            orderType:5,
            // serviceCode: 'cisFxZsyhGetDeliveryMethod',
            code:this.$store.state.user.userInfo.customer.cisCode,
            fxCustCisCode: this.$store.state.user.userInfo.customer.cisCode,
            dlCustCisCode:this.$store.state.user.userInfo.customer.cisCode,
            items: itema,
          }
          fxZsyhGetDeliveryMethod(data).then(res=>{
            if(res.data.code == 0) {
              this.options = res.data.data.items
              this.isShowLogisticsIcon = this.options.some(el => ['04', '08'].includes(el.code))
              // 获取接口中check == true 默认选中的下标
              this.transDefault = this.options.findIndex(el => el.check == true)>-1?this.options.findIndex(el => el.check == true):''
              // 赋值配送方式
              this.trans = this.options[this.transDefault].id
              // 是否展示服务费 根据配送方式中字段判断 // 0否，1是
              this.haveDeliveryFee = this.options[this.transDefault].haveDeliveryFee == 1? true : false
              this.$forceUpdate()
            }
          })
        })
        // 支付数据
        this.TotalPayment = res.data.balanceDTO
        this.TotalPayment.rebateAccount = this.TotalPayment.rebateAccount > rebate ? this.$util.getFloat(rebate, 2) : this.TotalPayment.rebateAccount;
        // this.totalAdvanceMoney = res.data.balanceDTO.totalAdvanceMoney;
        this.goodsPrice = res.data.balanceDTO.totalMoney
        this.suppilers = res.data.detail.fwOrgName + '-' + res.data.detail.matklName
        this.orgCode = res.data.detail.fwOrgCode
        this.getsaleShopList()
        this.getService()
        if(this.orderDis.length > 0 ) {
          let obj = this.orderDis.find((i)=>{
            return i.id ===  res.data.customerAddressDTOs[0].id;//筛选出匹配数据
          });

        }

      }).catch(err => {
        console.log(err);
      })
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {

      this.startTime = defaultTime;
    },
    // 控制可选时间范围
    disabledDate(current) {
      let nowDate = moment().format("YYYY-MM-DD")
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(nowDate) || current > moment(nowDate + ' 23:59:59').add(29, 'd')
    },
    // 子组件选择商品
    // 查询库存数
    stock(goodsData, i) {
      getNumber({
        code: goodsData.code,
        orgId: goodsData.orgId
      }).then(resD => {

        var newObject = Object.assign(this.GoodsList[i], resD.data[i])
        this.GoodsList[i] = newObject;

      })
    },

    showModal() {
      this.visible = true;
    },
    handleOk(e) {

      this.visible = false;
    },
    moreBill() {

    },
    onGiveType(e) {
      // this.trans = e.data.key
      this.trans = this.options[e.target.value].id;
      // 是否展示服务费 根据配送方式中返回字段判断 // 0否，1是
      this.haveDeliveryFee = this.options[e.target.value].haveDeliveryFee == 1? true : false
      if(this.trans !='502004' && this.trans != '502008'){
        this.provinceCode = ''
        this.cityCode = ''
        this.districtCode = ''
        this.townCode = ''
        this.addressHx = []
      }
      this.arrivalTimeStr = ''
      this.startShowTime = ''
      // if (this.houseAddress != '统仓地址') {
      // if (e.target.value == 3) {
      // 修改判断如果选择选项为直配到用户
      if( this.trans == 502004 ) {
          this.distributors = false;
          this.userAdderss = true;
          this.TCInt = false;
          getPhone({
            sendToId: this.receiveID
          }).then(res => {
            this.contacts = res.data.contact == '无' ? '' : res.data.contact;
            this.phoneCon = res.data.phone == '无' ? '' : res.data.phone;
          })
        this.changePhoneNum()
      }
      else if (this.trans == 502008) {
        this.distributors = false;
        this.userAdderss = true;
        this.TCInt = false;
        this.changePhoneNum()
      } else if (this.trans == 502005) {

          if (!this.DisList || this.DisList.length == 0) {
            this.$warning({
              content: '暂无可选分销商，请选择其他配送方式',
            });
            // this.$message.info("暂无可选分销商，请选择其他配送方式");
            return;
          }
          this.distributors = true;
          this.userAdderss = false
          this.TCInt = true;
          // 分销商 分销地址
          this.getDISADDList();
          this.showAnotheer()

        } else {

            getPhone({
                sendToId: this.receiveID
            }).then(res => {
                this.contacts = res.data.contact == '无' ? '' : res.data.contact;
                this.phoneCon = res.data.phone == '无' ? '' : res.data.phone;
                this.changePhoneNum()
            })
          this.distributors = false;
          this.userAdderss = false;
          this.TCInt = true;
        }
      // }
    },
    // 分销商 分销地址
    showAnotheer() {
        getPhone({
            sendToId: this.distributionAdd.DISCODE
        }).then(res => {

            this.contacts = res.data.contact;
            this.phoneCon = res.data.phone;
            this.changePhoneNum()
        })
    },
    onSubmit() { //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert('submit!');
        } else {

          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 截止时间选择
    endTimeChange(value) {

      this.endTime = value;

    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
      this.accountShow = !this.accountShow;
    },
    // 开票户头相关函数
    handleChange(value, e) {
      this.invoiceId = e.data.key
      this.invoice = value;
      this.accountShow = !this.accountShow;
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {

    },
    // 直送用户/送装一体地区校验
    deliveryAddressCheck() {
      const orgparamsList = this.GoodsList.map(el => {
        return {
          orgId: this.orgId,
          matklId: el.baseProductInfo.matklCode
        }
      })
      let data = {
        "serviceCode":"cisDeliveryAddressCheck",
        "provinceCode":this.provinceCode,
        "cityCode":this.cityCode,
        "districtCode":this.districtCode,
        "townCode":this.townCode,
        "custId":this.$store.state.user.userInfo.customer.id,
        orgparamsList:orgparamsList,
        orderType:1,
        deliveryMethod:this.trans=='502004'?'04':'08',
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费

      }
      return ctsApi(data)
    },
    async isCheckShipTo(){
      let res = await checkShipTo({shipToId:this.receiveID })
      console.log('res22',res)
      return res.data.data.isInvalidRegion
    },
    getAddress(e) {
      console.log('eeeee',e)
      this.newTown = e[3]
      console.log('this.newTown ',this.newTown )
    },
    // 点击提交订单按钮
    async orderSubmit() {
      if(['502001', '502002'].includes(this.trans) && this.houseAddress == '仓库地址' && !this.newTown) {
        let checkRes= await this.isCheckShipTo()
        if(checkRes == 1) {
          // 上面不符合条件的弹出弹窗
          this.visibleAddress = true
          return
        }
      }
      if(['502004', '502008'].includes(this.trans)) {
        // 判断下单产品是否支持直送用户
        const index1 = this.options.findIndex(el => el.code == '04')
        if(['502004'].includes(this.trans) && this.options[index1].msg) {
          this.$message.warning(this.options[index1].msg)
          return false
        }
        // 判断下单产品是否支持送装一体
        const index2 = this.options.findIndex(el => el.code == '08')
        if(['502008'].includes(this.trans) && this.options[index2].msg) {
          this.$message.warning(this.options[index2].msg)
          return false
        }
        if(!this.townCode) {
          this.$message.warning('请选择收货地区')
          return false
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
        if(!this.salesShopInfoId) {
          this.$message.warning('请选择销量所属门店')
          return false
        }
        if(this.TipsIds.length == 0) {
          this.$message.warning('请选择用户标签')
          return false
        }
        if(this.SorceIds == '') {
          this.$message.warning('请选择用户来源')
          return false
        }
        const res =  await this.deliveryAddressCheck()
        if(res.data.code == 1) {
          this.$message.warning(res.data.msg)
          return false
        }
      }
      if (this.invoiceId == '') {
        this.$message.warning('请选择开票户头');
        this.isLoading = false
        return;
      }
      if(!this.trans) {
        this.$message.warning('请选择配送方式');
        this.isLoading = false
        return;
      }
      if (this.startTime == '') {
        this.$message.warning('请填写截止日期');
        this.isLoading = false
        return;
      }
      if (!this.orderDis || this.orderDis.length == 0) {
        this.$message.warning('暂无可选收货地址，无法提交');
        this.isLoading = false
        return;
      }
      if (this.receiveID == '') {
        this.$message.warning('请填写收货地址');
        this.isLoading = false
        return;
      }
      if (this.userAdderss) {
        if(!(this.districtCode && this.townCode)){
          this.$message.warning('请选择收货地址');
          this.isLoading = false
          return;
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
      }
      if (!this.contacts) {
        this.$message.warning('请填写联系人信息');
        this.isLoading = false
        return;
      }
      if (!this.phoneCon) {
        this.$message.warning('请填写手机号');
        this.isLoading = false
        return;
      }
      if (this.phoneCon.trim().length !== 11) {
        this.$message.warning("手机号不正确，请重新填写");
        return;
      }
      if (this.isC20Business && !this.orderNumber) {
        this.$message.warning("订单备注必填，请填写财务中台收款认领通知号后10位。");
        return;
      }
      // else if (!(/^1[3456789]\d{9}$/.test(this.phoneCon))) {
      //   this.$message.warning("手机号码有误，请重填");
      //   return;
      // }
      if (this.goodsPrice > this.TotalPayment.canUseMoney) {
        this.$message.warning('订单金额不得大于账户可用余额');
        this.isLoading = false
        return;
      }
      if( this.trans== '502004' ||this.trans== '502008') {
        // 判断零售价格在建议零售价0.5倍到1倍之间。
        let arr = this.GoodsList
        for(let i = 0; i < arr.length; i++){
          if (!arr[i].salePrice) {
            this.$message.warning(`请输入第${i+1}件商品的零售价格`)
            return false
          }
          // if ( Number(arr[i].retailPrice) && (( Number(arr[i].salePrice) <  Number(arr[i].retailPrice * 0.5) ) || (  Number(arr[i].salePrice) >  Number(arr[i].retailPrice * 1) ))){
          //   this.$message.warning(`建议零售价为${arr[i].retailPrice}元，可填范围在0.5倍到1倍之间。`)
          //   return false
          // }
          if (arr[i].type == 1 && arr[i].salePrice) {
            if (Number(arr[i].salePrice) < arr[i].min || Number(arr[i].salePrice) > arr[i].max) {
              if (arr[i].haveRetailL) {
                this.$message.warning(`请在默认建议零售价的${arr[i].bei}倍范围内录入价格`);
              } else {
                this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
              }
              return;
            }
          }
        }
      }
      let saleId = ''
      if(this.trans == '502004'||this.trans == '502008') {
        saleId = this.salesShopInfoId
      } else {
        saleId = ''
      }
      const packageProductList = this.GoodsList.map(el => {
        return {
          packageProductId: el.id,
          retailPrice: el.salePrice
        }
      })
      let data = {
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
        packageProductList:JSON.stringify(packageProductList),
        arrivalTimeStr:this.arrivalTimeStr&&this.haveDeliveryFee?this.arrivalTimeStr.replace(/\//g,"-")+' 23:59:59':'',
        //供应商和物料组
        orgMatkl: this.obj.orgId + '-' + this.obj.matklId,
        id: this.RouterParams.mainId,
        num: this.RouterParams.num,
        // 送达方 商家id
        billTo: this.invoiceId,
        // 商家名字
        address1: this.receiveID,
        trans: this.trans,
        provice: this.provinceCode,
        city: this.cityCode,
        // district: this.userAdderss ? this.proObj.townCode : '',
        contact: this.contacts,
        mobile: this.phoneCon,
        endDate: this.startTime,
        rebateValue: this.obj.balanceDTO.rebate,
        rebateAccount: this.obj.balanceDTO.rebateAccount,
        // 客户采购单号
        orderCode: this.orderNumber,
        // 用户手填地址
        address3: this.userDetailAddress,
        // // 分销商地址 id
        address4: this.distributors ? this.distributionAdd.DISADD : '',
        maxEndDate: this.maxEndDate,
        orderRebate: this.isTradeChecked ? "Y" : "N",
        officeId: this.officeCode,
        salesShopInfoId:saleId,
         // 服务列表
         serviceTypeCodes:this.fwlbId.join(','),
         district: this.userAdderss ? this.districtCode : '',
        town: this.userAdderss ? this.townCode : '',
        customerSex:(this.trans != '502004'&&this.trans != '502008') ? '' : this.sexId,// 性别
        cusomerSource:(this.trans != '502004'&&this.trans != '502008') ? '' :this.SorceIds,// 用户来源
        customerLabel:(this.trans != '502004'&&this.trans != '502008') ? '' :this.TipsNames.join(','), // 用户标签
        validTown:(this.trans == '502001'||this.trans == '502002')&& this.houseAddress == '仓库地址'?this.newTown:''

      }
      this.isLoading = true;
      specialSubmit(data).then(res => {
        // var orderList = JSON.stringify(res.data.list);
        if (res.data.code == 1) {
          this.$message.warning(res.data.msg)
          this.isLoading = false;
        } else if (res.data.code == 400) {
          // this.$message.warning(res.data.msg);
          this.isLoading = false;
        } else if (res.data.b2bOrderCode) {
          if(res.data.serviceFeeCode) {
            this.$router.push({
              path: "/serviceFeeModule/pay",
              query: {
                id:res.data.serviceFeeCode,
                batch:0,
                b2bOrderCode: res.data.b2bOrderCode || res.data.orderCode,
                orgName: '订单号'
              }
            });
            return
          }
          this.$router.push({
            path: '/confirm/confirmsuccess',
            query: {
              b2bOrderCode: res.data.b2bOrderCode || res.data.orderCode,
              orgName: '订单号'
            }
          })
        }else if(res.data.error ||res.data.b2berror) {
          this.$message.warning(res.data.error||res.data.b2berror)
          this.isLoading = false;
        }
      })
    },
    // 获取子组件选中商品
    sonCheckList(data) {

      let cheacked = this.sonChectItme.indexOf(data)
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1)
      }

    },
    //   点击返回购物车
    goCar() {
      this.$router.go("-1");
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 收货地址下拉框选择
    handleChangeAddress(value, e) {
      let obj = this.orderDis.find((i)=>{
        return i.id ===  e.key;//筛选出匹配数据
      });

      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      this.warehouseInfoId = obj.shareWarehouseId;
      this.receiveAdd = !this.receiveAdd
      // this.SAddressChange(e.data.key)
      // 获取手机号
      getPhone({
        sendToId: e.key
      }).then(res => {

        this.contacts = res.data.contact;
        this.phoneCon = res.data.phone;
      })
    },
    handleChangeAddressHis(value, e) {

      this.receivehis = value;
      // 收货地址 选中 id
      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;

    },
    // 切换收货地址
    SAddressChange() {
      // showAnother({
      //   idid
      // }).then(res => {
      // }).catch(err => {
      //   console.log(err)
      // })
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;
      this.distributionAdd.DISCODE = e.key;

      this.DISADDPARAMS.customerId = e.key;
      this.getDISADDList()
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data).then(res => {

        this.choseDistribution = res.data;
        this.distrAddress = res.data && res.data.length > 0 ? res.data[0].address:'';
        this.distributionAdd.DISADD = res.data && res.data.length > 0 ? res.data[0].id:''
        //切换分销商获取地址列表请求联系人
        this.getPeople(res.data && res.data.length > 0 ? res.data[0].id:'');
      }).catch(err => {
        console.log(err);
      })
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;

    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISADD = e.key
      this.distrAddress = value;
      //切换分销商获取地址列表请求联系人
      this.getPeople(e.key);
    },
    // 获取手机号
    getPeople(sendToId) {
        getPhone({
            sendToId
        }).then(res => {
    
            this.contacts = res.data.contact == "无" ? '' : res.data.contact;
            this.phoneCon = res.data.phone == "无" ? '' : res.data.phone;
        })
    },
    addItem() {

      //this.items.push(`New item ${index++}`);
    },
    // 监听输入变化
    handleChangeInt(e) {

    },
    // 弹框
    ToDetail() {
      this.modelShow = true;
      this.pageLoadTable = true
      var data = {
        orgIdParam: this.orgId,
        matklIdParam: this.matklId,
        weekName: this.selected,
        purchaseType: this.RouterParams.purchaseType,
        pageNo:1,
        pageSize:10000,
      }
      quotaDeatail(data).then(res => {
        this.pageLoadTable = false;
        this.dataAll = res.data.dataList.list;
        this.data =res.data.dataList.list.slice(0, 10)
        this.pager.count = res.data.dataList.list.length;
      })
    },
    // 分页
    // 分页
    pagesiezes(data) {
      // var prev = data - 1;
      var start = (data - 1) * this.pager.pageSize;
      this.data = this.dataAll.slice(start, start + this.pager.pageSize)
      this.pager.pageSize;

    },
    handleWeek(value, e) {

      this.endTime = e.key;
      this.selected = value
    },
    // 海信办事处
    hisenseOffice(e) {
      this.hisenseOff = !this.hisenseOff;
      this.officeCode = e.data.key;

    },
    // 智能识别
    recognitionFun(res){
      const townCode = res.levelAddress.town_code;
      // 收货地址
      this.addressDftVal = [townCode.slice(0,2).padEnd(12, 0),townCode.slice(0,4).padEnd(12, 0),townCode.slice(0,6).padEnd(12, 0),townCode.padEnd(12, 0)];
      // 收货地址 code
      this.provinceCode = townCode.slice(0,2).padEnd(12, 0);
      this.cityCode = townCode.slice(0,4).padEnd(12, 0);
      this.districtCode = townCode.slice(0,6).padEnd(12, 0);
      this.townCode = townCode.padEnd(12, 0);
      // 详细地址
      this.userDetailAddress = res.address;
      // 姓名
      this.contacts = res.userName;
      // 电话
      this.phoneCon = res.mobile;
    }
  }
}