<!--
 * @Author: sq
 * @Date: 2020-09-18 11:15:28
 * @LastEditTime: 2020-11-12 10:15:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\projectCollapse\projectCollapse.vue
-->

<template>
  <div class="project-box">
    <div class="project-collapse-box">
      <div class="project-collapse-wrap">
        <div class="project-collapse">
          <div class="project-collapse-menu" v-for="(item, index) in data" :key="index">
            <div class="project-collapse-menu-img">
              <h-img class="imgBox" :imgUrl="item.img" :errorImg="item.defaultImg" :isUseBasePath="true"></h-img>
            </div>
            <div class="project-collapse-menu-data" :style="{'width':(trans=='502004'||trans=='502008')?'180px':'240px'}">
              <div
                class="project-collapse-menu-data-title titleName"
              >
                <span  v-if="item.productLabel && item.productLabel.indexOf('15691143850')!==-1" class="freeShipping">免运费</span>
                <span  v-if="item.productLabel && item.productLabel.indexOf('15691143849')!==-1" class="bright">璀璨</span>
                {{ item.baseProductInfo.zzprdmodel }}</div>
              <div class="project-collapse-menu-data-desc " style="margin-top:10px">
                品牌：{{ item.baseProductInfo.z_brand || '--'}}
                <span>颜色：{{ item.baseProductInfo.z_color || '--'}}</span>
              </div>
              <div class="project-collapse-menu-data-desc">体积：{{ item.loadVolume || '--'}} <span>批次：{{ item.batchId_name || '--'}}</span></div>
            </div>
<!--            判断根据配送方式不同展示不通样式-->
            <div class="project-collapse-menu-data project-collapse-menu-datal" :style="{'width':(trans=='502004'||trans=='502008')?'180px':'240px', 'position': 'relative'}">
              <div class="project-collapse-menu-data-title">
                <span class="project-collapse-menu-data-title titleName">
                  ￥{{ $util.getFloat(
                  item.billPrice
                  ,2)}}

                </span>
                <span class="project-collapse-menu-data-title-zhekou" v-if=" item.standardPrice">
                 ￥{{$util.getFloat(
                  item.standardPrice
                  ,2)}}
                </span>
              </div>
              <div class="project-collapse-menu-data-desc " style="margin-top:10px">
                直扣:￥{{ item.straightBuckle}}
              </div>
              <div class="rebate-div" v-if="item.isShowRebate && item.batchId_name == 'H'">最高可享台返{{Number(item.returnAmount || 0).toFixed(2)}}元，政策后提货价{{Number(item.billPrice - (item.returnAmount || 0)).toFixed(2)}}元，限量{{item.upperLimit || 999}}台</div>
<!--              <div class="project-collapse-menu-data-desc">-->
<!--                已购:{{ item.buyCount}}-->
<!--              </div>-->
            </div>
            <div class="project-collapse-menu-data project-collapse-menu-datal3">
              <div
                v-if="RouterParams.num>1"
                class="project-collapse-menu-data-title titleName"
              >×{{ item.quantity/RouterParams.num }}</div>
              <div
                v-if="RouterParams.num ==1"
                class="project-collapse-menu-data-title titleName"
              >×{{ item.quantity }}</div>
            </div>
            <div class="project-collapse-menu-data project-collapse-menu-datal2" v-if="trans=='502004'||trans=='502008'">
              <div class="project-collapse-menu-data-title">
                <span class="project-collapse-menu-data-title titleName">
                  小计
<!--                  ￥{{ $util.getFloat(-->
<!--                    item.billPrice*(item.quantity/RouterParams.num)-->
<!--                    ,2)}}-->
                </span>
              </div>
              <div class="project-collapse-menu-data-desc firstText">
                货款：￥{{ $util.getFloat(item.billPrice*(item.quantity/RouterParams.num),2)}}
              </div>
              <div class="project-collapse-menu-data-desc price" v-if="haveDeliveryFee">
                服务费：￥{{item.serviceMoney?Number(item.serviceMoney).toFixed(2):'0.00'}}
              </div>
              <div class="project-collapse-menu-data-desc price">
                零售价格：￥
                <span  :style="{color:Number(item.salePrice)>0?'#262626':'#777'} " v-if="!item.idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}</span>
                <a-input oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)" type="text" @blur="blurPrice(item)" v-if="item.idEdit" @change="changePrice(item,$event)" style="width: 100px" v-model="item.salePrice" />
                <span class="edit" @click="edit(item)">{{item.idEdit?'完成':'编辑'}}</span>
              </div>
            </div>
            <br />
          </div>
        </div>

        <div class="project-control">
          <div class="project-control-title">
            <div class="project-control-title-top">组合内包含</div>
            <div class="project-control-title-btm">
              {{ RouterParams.chooseProductClass }} 个型号，{{RouterParams.chooseProductNum }}
              件商品
            </div>
          </div>
          <div class="project-control-content">
            <div class="project-control-content-price">
              <span class="project-control-content-price-title">组合单价：</span>
<!--              <span-->
<!--                class="project-control-content-price-desc"-->
<!--              >￥{{ $util.getFloat(RouterParams.chooseSingleProductPrice,2) }}</span>-->
              <p class="priceNum">
                <span class="fh">￥</span>
                <span class="jg before"  style="font-size: 22px;font-weight: 500;color:#777777;">{{ RouterParams.chooseSingleProductPrice ? Number(RouterParams.chooseSingleProductPrice).toFixed(2).toString().split('.')[0] : '0' }}</span>
                <span class="jg after" style="font-size:12px;"><i class="dian">.</i>{{ RouterParams.chooseSingleProductPrice ? Number(RouterParams.chooseSingleProductPrice).toFixed(2).toString().split('.')[1] : '00'}}</span>
              </p>
            </div>
            <div class="project-control-content-desc count">
              <span class="title">购买套数：</span>
              <span class="desc">{{RouterParams.num}}套</span>
            </div>
<!--            <div class="project-control-content-desc amount">-->
<!--              <span class="title">小计：</span>-->
<!--              <span class="desc">{{$util.getFloat(RouterParams.chooseProductPrice,2)}}元</span>-->
<!--            </div>-->
          </div>
          <!-- <div class="project-control-price">
            <span class="project-control-price-title">组合单价：</span>
            <span class="project-control-price-desc">{{ RouterParams.chooseSingleProductPrice }}元</span>
          </div>
          
          <div class="project-control-num-title">
            购买套数：
            <span>{{RouterParams.num}}套</span>
          </div>
          <div class="project-control-num-title">
            小计：
            <span>{{$util.getFloat(RouterParams.chooseProductPrice,2)}}元</span>
          </div>-->
          <!-- <div class="project-control-num">
            <div class="project-control-num-title" >
              购买套数：
              <span>{{RouterParams.num}}套</span>
            </div>
            <div class="project-control-num-title" >
              小计：
              <span>{{$util.getFloat(RouterParams.chooseProductPrice,2)}}元</span>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { number } from 'echarts/lib/export';
export default {
  data() {
    return {
      isSelectAll: false,
      chooseProductNum: 0,
      chooseProductPrice: 0,
      chooseProductVolume: 0,
      chooseProductClass: 0,
      watchNum: 0,
    };
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
    RouterParams: {
      type: [Array, Object],
      default: () => []
    },
    canBuy: {
      type: Boolean
    },
    chooseNum: {
      type: [Number, String]
    },
    dataIndex: {
      type: [Number, String]
    },
    trans: {
      type: [Number, String]
    },
    haveDeliveryFee: {
      required: true
    },

  },
  watch: {
    data:{
      immediate: true,
      handler(val) {
        val.forEach(item=>{
          item.straightBuckle = Number(item.straightBuckle).toFixed(2)
          item.billPrice = Number(item.billPrice).toFixed(2)
          item.idEdit = false

        })
      }
    },
    chooseNum: {
      immediate: true,
      handler(val) {
        this.watchNum = val;
      }
    },
    trans: {
      immediate: true,
      handler(val) {

      }
    },
    haveDeliveryFee: {
      immediate: true,
      handler(val) {

      }
    },
  },
  computed: {
    canSelect: () => {
      let num = 0;
      this.data.productItem.forEach(element => {
        if (element.canSelect) {
          num += 1;
        }
      });
      return num;
    }
  },
  mounted() {
    // console.log(146546);
    // console.log('222',this.data);
  },
  methods: {
    edit(item) {
      item.idEdit = !item.idEdit
      this.$forceUpdate()
    },
    changePrice(item,e){
      this.$emit("changePrice", item);
    },
    blurPrice(item,e){
      item.salePrice=item.salePrice.replace(/[^\-?\d.]/g,'')
      item.salePrice= Number(item.salePrice).toFixed(2)
      if (item.type == 1 && item.salePrice) {
        if (Number(item.salePrice) < item.min || Number(item.salePrice) > item.max) {
          if (item.haveRetailL) {
            this.$message.warning(`请在默认建议零售价的${item.bei}倍范围内录入价格`);
          } else {
            this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
          }
          item.salePrice = item.retailPrice;
          this.$forceUpdate()
          return;
        }
      }
      this.$emit("changePrice", item);
    },
    // 展开
    async isCollapseProOpen() {
      this.data.isCollapse = true;
    },

    handleSubmit() {
      this.$emit("handleSubmit");
    },

    //当参数发生变化
    dataChange(num) {
      this.initProduct();
      let chooseProductNum = 0,
        chooseProductPrice = 0,
        chooseProductClass = 0,
        chooseProductVolume = 0;
      this.data.forEach(item => {
        chooseProductPrice += num * item.billPrice * item.qty;
        chooseProductVolume += num * item.loadVolume * item.qty;
        chooseProductClass += num > 0 ? 1 : 0;
        chooseProductNum += num * item.qty;
      });
      this.chooseProductNum = chooseProductNum;
      this.chooseProductPrice = chooseProductPrice.toFixed(3) || "0.00";
      this.chooseProductClass = chooseProductClass;
      this.chooseProductVolume = chooseProductVolume.toFixed(3) || "0.00";
    },

    //初始化产品信息
    initProduct() {
      this.chooseProductNum = 0;
      this.chooseProductPrice = 0;
      this.chooseProductClass = 0;
      this.chooseProductVolume = 0;
    },

    //获取物流提示
    getInfo() {
      this.$info({
        title: "体积提示",
        content: this.$store.state.user.getVolumnText
      });
    }
  }
};
</script>
<style lang="less" scoped>
@cardImgW: 196px;
@col26: #262626;
@col77: #777777;
@accountBtnW: 200px;
@f12: 12px;
@f14: 14px;
@f16: 16px;
@f18: 18px;

.line {
  width: 860px;
  height: 1px;
  background: #eeeeee;
  // border: 1px solid #eeeeee;
  // margin-top: 22px;
  margin-left: 24px;
}

.settlement {
  width: 908px;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 30px;
  .settleTitle {
    font-size: 14px;
    color: #777777;
    line-height: 16px;
    margin-left: 24px;
    margin-bottom: 8px;
    margin-top: 16px;
  }
  .settlementVal {
    width: 800px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: 24px;
    p {
      width: 120px;
      text-align: left;

      font-size: 12px;
      color: #777;
      line-height: 16px;
      margin-right: 50px;
      margin-top: 8px;
    }
  }
}
.project-box {
  width: 100%;
  height: auto;
  margin: 0 auto;
  background: #fff;
  //   内容区域
  .project-collapse-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 8px;
    box-sizing: border-box;
    .project-collapse {
      text-align: left;
      // box-shadow: 0px 1px 5px 2px rgba(238, 238, 238, 0.7);
      // padding-bottom: 10px;
      box-sizing: border-box;
      &-title {
        padding: 25px 24px 15px;
        font-size: @f16;
        color: @col26;
        font-weight: 600;
      }
      &-menu {
        width: 908px;
        background: #F7F7F7;
        padding: 22px 24px;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        border-radius: 8px;

        &:last-child {
          border: none;
          margin-bottom: 0;
        }
        &-img {
          width: 100px;
          height: 100px;
          background: #fff;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          .imgBox {
            width: 80px;
            height: 80px;
            img{
              width: 80px;
              height: 80px;
            }
          }
        }
        &-data {
          width: 180px;
          height: 100px;
          margin-left: 24px;
          text-align: left;
          padding-top:10px;

          &-title {
            font-size: @f14;
            color: @col26;
            font-weight: 600;
            &-zhekou {
              text-decoration: line-through;
              color: #777;
              font-weight: normal;
              margin-left: 15px;
              font-size: 12px;
            }
          }
          &-desc {
            font-size: @f12;
            color: #777;
            margin-top: 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .firstText {
            margin-top: 10px;
            font-weight: 400;
            color: #262626;
          }
          .price {
            font-weight: 400;
            color: #262626;
            span {
              margin-left:0
            }
            .edit {
              margin-left:24px;
            }
          }

        }

        &-datal2 {
          width: 230px;
          .edit{
            color:#1890FF;
            cursor: pointer;
          }
        }
        &-datal3{
          width: 68px;
        }
        &-control {
          &-box {
            max-width: 120px;
            input {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .project-control {
    .project-control-price-desc {
      font-size: 18px;
      color: #262626;
    }
    .project-control-num {
      text-align: center;
      .project-control-num-title {
        text-align: center;
        span {
          font-size: 22px;
          color: #262626;
        }
      }
    }
  }
  .project-collapse-menu-data-desc {
    span {
      margin-left: 16px;
    }
  }
  .rebate-div{
    height: 16px;
    line-height: 16px;
    color: #FC5260;
    font-size: 12px;
    position: absolute;
    top: 65px;
    left: 0;
    width: 200px;
  }

  .project-account {
    width: 1188px;
    background: #fff;
    margin: 0 auto;
    border-top: 16px solid #f7f7f7;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 18px 54px 18px 16px;
      width: calc(100% - @accountBtnW);
      &-right {
        &-price {
          font-size: 24px;
          font-weight: 600;
        }
        .color-red {
          color: #d9161c;
        }
      }
    }

    &-btn {
      width: @accountBtnW;
      height: 100%;
      &-a {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding: 18px 0;
        box-sizing: border-box;
        font-size: 16px;
      }
    }
  }

  .project-box {
    width: 1188px;
    margin: 16px auto 0;
    &-header {
      box-sizing: border-box;
      padding: 24px 0 0 0;
      background: #fff;
      display: flex;
      &-data {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        height: 64px;
        align-items: center;
        width: 100%;
        &-price {
          height: 100%;
          color: #777777;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-end;
          width: 450px;
          margin-right: 16px;
          &-inset {
            display: flex;
            flex-wrap: wrap;
            span {
              display: flex;
              align-items: center;
              margin-left: 18px;
              .toast {
                width: 20px;
              }
            }
          }
        }
      }
      &-submit {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        &-button {
          margin-left: 24px;
          width: 140px;
          height: 40px;
          background: #00aaa6;
          color: #fff;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }

    &-content {
      margin-top: 12px;
    }
  }
}
.project-control {
  margin-left: 16px;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 215px;
  height: 304px;
  background: linear-gradient(360deg, #EEF9F9 0%, #FBFBFB 100%);
  border-radius: 8px;
  &-title {
    // position: absolute;
    // top: 0;
    width: 160px;
    height: 43px;
    background: #ecf8f8;
    color: #00aaa6;
    font-size: 12px;
    margin: 0 auto;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;

    &-top {
      text-align: center;
    }
    &-btm {
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &-content {
    width: 100%;
    height: calc(100% - 44px);
    padding: 0 0 40px;
    display: flex;
    flex-direction: column;
    //justify-content: space-around;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-left: 28px;
    &-price {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
       margin:44px 0 39px 0;
      display: flex;
      flex-direction: row;
      align-items:flex-end;
      font-size: 14px;
      color: #777777;
      &-title {
        font-size: 14px;
      }
      .priceNum {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .before {
          line-height: 22px;
        }
        .fh {
          color:#777777;
          font-weight:500;
        }
        .jg {
          color:#777777;
          font-weight:500;
        }
      }
      &-desc {
        font-size: 18px;
      }
    }
    &-desc {
      color: #262626;
      font-size: 14px;
      text-align: left;
      // margin-bottom: 16px;
      &.count {
        .title {
          font-size: 14px;
          color: #777777;
        }
        .desc {
          font-size: 14px;
          color: #777777;
        }
      }
      &.amount {
        .title {
          font-size: 14px;
        }
        .desc {
          font-size: 22px;
          // font-weight: 600;
        }
      }
    }
  }
}
.titleName {
  font-weight: normal !important;
  .freeShipping {
    font-size: 12px;
    color: #fff;
    width: 42px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
    border-radius: 2px;
    margin-right: 0px;
    font-weight: 400;
  }
  .bright {
    font-size: 12px;
    color: #433538;
    width: 30px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
    border-radius: 2px;
    margin:0 0px 0 4px;
    font-weight: 400;
  }
}
.project-control-content-price-desc,
.desc {
  color: #d9161c;
}
.project-control-content {
  padding-left: 23px;
}
.project-collapse-menu {
  flex-wrap: wrap;
}
</style>
